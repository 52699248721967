import React from 'react';

/**
 * Content for user Guide for the HCP portal
 * @param {hash} images - A hash that contains the asset path for all images used on the page
 * @param {string} release - The server's current release
 */

const UserGuideContent = ({images, release, device_version}) => {
  return (
    <main className="user-guide-content">
      <div className="modal modal-blurred" onClick={(e) => {e.currentTarget.classList.remove("modal-show")}}>
        <img src={images.hcp_flow} alt="HCP user flow diagram" className="modal-image"/>
      </div>
      <div className="user-guide-header">
        <h1 id="pd-0">What is Canvas Dx&trade;</h1>
        <h2 className="italic-header">Rx only</h2>
      </div>
      <div id="pd-1">
        <h2 className="space-small">Product Description</h2>
        <p className="space-large">
          Canvas Dx is a prescription diagnostic aid to healthcare professionals (HCP) considering the diagnosis of Autism Spectrum Disorder (ASD) in patients 18 months through 72 months of age at risk for developmental delay. The patient’s caregiver uses a smartphone application (“App”) to fill out a caregiver questionnaire and to make video recordings of behavior at home, and then the HCP fills out a medical questionnaire online. The results are made available to the HCP online.
        </p>
        <div className="guide-img space-large">
          <img src={images.hcp_flow} alt="HCP user flow diagram" className="clickable-img hcp--wide" onClick={() => {document.querySelectorAll(".modal")[1].classList.add("modal-show")}}/>
        </div>
        <p className="space-small">
          Canvas Dx (“Device”) utilizes a machine-learning algorithm that receives independent inputs from caregivers, trained analysts, and healthcare professionals (see Table 1). If there is enough information to make a reliable determination, Canvas Dx produces an input that the patient likely does or does not have ASD based on developmental behaviors that are indicative for autism.
        </p>
        <table className="user-guide-table space-small">
          <thead>
            <tr>
              <th className="hcp--cell-min-width">Device Input</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Caregiver Questionnaire</td>
              <td>The patient’s caregiver provides answers to questions on key developmental behaviors (by age group) through the App.</td>
            </tr>
            <tr>
              <td>Patient Video Analysis</td>
              <td>The patient’s caregiver provides two videos of the patient’s natural play, communication, and social behavior at home using the App. Cognoa-trained analysts then review the videos remotely and answer questions about the patient’s behavior using the Cognoa Video Analyst Portal.</td>
            </tr>
            <tr>
              <td>Healthcare Professional (HCP) Questionnaire</td>
              <td>The patient’s HCP answers questions on key developmental behaviors for the patient’s age group using the HCP portal.</td>
            </tr>
          </tbody>
        </table>
        <p className="blue-text space-medium hcp--caption">Table 1. Inputs to Algorithm</p>
        <p>
          Once all information has been collected, the HCP will be able to review the result on the HCP portal within 1-3 business days. The Device’s result will aid in the diagnosis of ASD, or help determine that the patient does not have ASD (see Table 2).
        </p>
        <table className="user-guide-table space-small">
          <thead>
            <tr>
              <th className="hcp--cell-min-width">Cognoa Device Output</th>
              <th>Output Meaning</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Positive for Autism Spectrum Disorder (Positive ASD)</td>
              <td>The patient has Autism Spectrum Disorder if the healthcare professional confirms the clinical presentation of the patient is consistent with and meets diagnostic criteria for ASD.</td>
            </tr>
            <tr>
              <td>Negative for Autism Spectrum Disorder (Negative ASD)</td>
              <td>The patient does NOT have Autism Spectrum Disorder if the healthcare professional confirms the clinical presentation of the patient is consistent with ruling out ASD and does NOT meet diagnostic criteria for ASD. A negative result does not necessarily mean that the patient will not develop ASD in the future and continued monitoring or evaluation for non-ASD conditions may be warranted.</td>
            </tr>
            <tr>
              <td>No Result</td>
              <td>The available information does not allow the algorithm to render a reliable result. This does not mean that the patient either has or does not have ASD.</td>
            </tr>
          </tbody>
        </table>
        <p className="blue-text space-medium hcp--caption">Table 2. Outputs of Algorithm</p>
        <p className="space-medium">
          In all cases, the HCP should follow the established guidelines when making a medical decision. For example, those of the American Academy of Pediatrics: Identification, Evaluation, and Management of Children with Autism Spectrum Disorder.
        </p>
      </div>

      <div id="pd-2">
        <h2 className="space-small">Indications for Use</h2>
        <p className="space-medium">
          Canvas Dx is intended for use by healthcare providers as an aid in the diagnosis of Autism Spectrum Disorder (ASD) for patients ages 18 months through 72 months who are at risk for developmental delay based on concerns of a parent, caregiver, or healthcare provider.
        </p>
        <p className="space-medium">
          The device is not intended for use as a stand-alone diagnostic device but as an adjunct to the diagnostic process.
        </p>
        <p className="space-large">
          The device is for prescription use only (Rx only).
        </p>
      </div>

      <div id="pd-3">
        <h2 className="space-small" >Contraindications</h2>
        <p className="space-large">
          There are no contraindications to using the Canvas Dx.
        </p>
      </div>

      <div id="pd-4">
        <h2 className="space-medium">Precautions, Warnings</h2>
        <p className="space-medium">
          The Device is intended for use by healthcare professionals trained and qualified to interpret the results of a behavioral assessment examination and to diagnose ASD.
        </p>
        <p className="space-medium">
          The Device is intended for use in conjunction with patient history, clinical observations, and other clinical evidence the HCP determines are necessary before making clinical decisions. For instance, additional standardized testing may be sought to confirm the Device output, especially when the Device result is not Positive or Negative for ASD.
        </p>
        <p className="space-medium">
          Canvas Dx is intended for patients with caregivers who have functional English capability (8th grade reading level or above) and have access to a compatible smartphone with an internet connection in the home environment.
        </p>
        <p className="space-medium">
          The Device may give unreliable results if used in patients with other conditions that would have excluded them from the clinical study. Among those conditions are the following:
        </p>
        <ul>
          <li>Suspected auditory or visual hallucinations or with prior diagnosis of childhood onset schizophrenia</li>
          <li>Known deafness or blindness</li>
          <li>Known physical impairment affecting their ability to use their hands</li>
          <li>Major dysmorphic features or prenatal exposure to teratogens such as fetal alcohol syndrome</li>
          <li>History or diagnosis of genetic conditions (such as Rett syndrome or Fragile X)</li>
          <li>Microcephaly</li>
          <li>History or prior diagnosis of epilepsy or seizures</li>
          <li>History of or suspected neglect</li>
          <li>History of brain defect injury or insult requiring interventions such as surgery or chronic medication</li>
        </ul>
        <p>The Device evaluation should be completed within 60 days of the time it is prescribed because neurodevelopmental milestones change rapidly in the indicated age group.</p>
      </div>

      <div id="pd-5">
        <h2 className="space-small">Clinical Study Results</h2>
        <p className="space-large">
          The performance of the Device has been evaluated in a 425-patient, 14-site, prospective, clinical study that compared the Device output to a reference diagnosis based on DSM-5 criteria by a specialist clinician and corroborated with independent review by a group of specialist clinicians. All study participants and assessors were blinded to the results of the Device. Specialist clinicians were board-certified pediatric psychiatrists, pediatric neurologists, developmental-behavioral pediatricians, or psychologists with at least five years of experience diagnosing autism spectrum disorder.
        </p>
        <p className="space-large">
          The study population included female and male patients between the ages of 18 months through 72 months from a general practice population for whom a caregiver or HCP had a concern about developmental delay. The ASD prevalence in the study population was 29%.
        </p>
        <p className="space-large">
          The outcome measures of the study were the Positive Predictive Value (PPV) and the Negative Predictive Value (NPV) of the Device.
        </p>
        <ul className="space-large">
          <li>Positive Predictive Value (PPV) - Probability that a patient identified by the Device as Positive for ASD has ASD as determined by specialist clinicians.</li><br/>
          <li>Negative Predictive Value (NPV) - Probability that a patient identified by the Device as Negative for ASD does not have ASD as determined by specialist clinicians.</li>
        </ul>
        <p className="space-large">
          In the 32% of patients for which the Device provided a definitive answer, the Device demonstrated a PPV of 81% with a 95% confidence interval of (70%, 89%) and a NPV of 98% (91%, 100%). The Device performance was consistent across sex, race, ethnicity, income level, education level, and study site.
        </p>
        <table className="user-guide-table space-small hcp--centered-cell hcp--wide" role="presentation">
          <tbody>
            <tr>
             <td colSpan="2" rowSpan="2"></td>
             <td colSpan="3" className="hcp--dark-cell">Clinical Reference Standard</td>
            </tr>
            <tr>
             <td className="hcp--dark-cell">ASD Positive</td>
             <td className="hcp--dark-cell">ASD Negative</td>
             <td colSpan="2" className="hcp--dark-cell">Total</td>
            </tr>
            <tr>
              <td rowSpan="5" className="hcp--dark-cell">Cognoa Device</td>
            </tr>
            <tr>
              <td className="hcp--dark-cell">ASD Positive</td>
              <td>63</td>
              <td>15</td>
              <td>78</td>
            </tr>
            <tr>
              <td className="hcp--dark-cell">ASD Negative</td>
              <td>1</td>
              <td>56</td>
              <td>57</td>
            </tr>
            <tr>
              <td className="hcp--dark-cell">No Result</td>
              <td>58</td>
              <td>232</td>
              <td>290</td>
            </tr>
            <tr>
              <td className="hcp--dark-cell">Total</td>
              <td>122</td>
              <td>303</td>
              <td>425</td>
            </tr>
          </tbody>
        </table>

        <p className="blue-text space-medium hcp--caption">
          Table 3. Study Results Comparing Device to Clinical Reference Standard
        </p>

        <table className="user-guide-table space-small hcp--centered-cell hcp--wide">
          <thead>
            <tr>
              <th className="hcp--dark-cell">Measures</th>
              <th className="hcp--dark-cell">Value</th>
              <th className="hcp--dark-cell">95% Confidence Interval</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>PPV</td>
              <td>80.77% (63/78)</td>
              <td>70.27%, 88.82%</td>
            </tr>
            <tr>
              <td>NPV</td>
              <td>98.25% (56/57)</td>
              <td>90.61%, 99.96%</td>
            </tr>
            <tr>
              <td>No Result Rate</td>
              <td>68.24% (290/425)</td>
              <td>63.58%, 72.64%</td>
            </tr>
          </tbody>
        </table>
        <p className="blue-text space-medium hcp--caption">
          Table 4. Outcomes measures
        </p>

        <p className="space-large">
          The Device is designed to return no result when predictive ability is low. In 68% of patients, the Device did not provide a result because its predictive reliability was too low to be clinically meaningful using the information available. This procedure of abstaining from prediction when the model response has lower reliability is a well-understood method of risk control in machine learning algorithms. A trade-off is made between the reliability of the positive and negative ASD results and the proportion of patients who would obtain a result.
        </p>
        <p className="space-large">
          Within the cohort of patients who received No Result, 20% were found to be positive for ASD, 71% had a non-ASD neurodevelopmental condition, and 9% were found to be neurotypical. The study also measured the sensitivity and specificity of the Device.
        </p>
        <ul>
          <li>Sensitivity: Probability that a patient who specialist clinicians determine has ASD is identified by the Device as having ASD.</li>
          <li>Specificity: Probably that a patient who specialist clinicians determine does not have ASD is identified by the Device as not having ASD.</li>
        </ul>
        <p className="space-large">
          At the conclusion of the study, the Device demonstrated a sensitivity of 98% (92%, 100%) and a specificity of 79% (68%, 88%) in patients for whom the Device rendered a diagnostic output.
        </p>
        <p className="space-large">
          Risks related to the study were minimal with no adverse events reported during the study.
          <br/>
          Refer to ClinicalTrials.gov Identifier NCT04151290 for additional information about the clinical study design.
        </p>
      </div>

      <div>
        <h2 className="space-small" id="ug-0">HCP Portal User Guide</h2>
        <p className="space-medium">
          This guide will instruct you on how to use the Canvas Dx HCP Portal.
        </p>
        <p className="space-medium">
          Through the portal, you will be able to view patients’ progress through the Canvas Dx and to see if any inputs are pending (e.g., Awaiting Video, Awaiting Caregiver Answers). You will access the portal to provide answers to the HCP questionnaire and view the Canvas Dx result for each patient for whom the Device was prescribed.
        </p>
      </div>

      <div id="pd-6">
        <h2 className="space-small">Minimum Technical Requirements</h2>
        <h5>Caregiver-facing app:</h5>
        <p className="space-medium">
          Mobile Device Operating System: iOS 13 or 14/ Android 10 or 11 (excluding Android Go)<br/>
          Mobile Device Storage: 300 MB free<br/>
          Mobile Device Camera: Back facing<br/>
          Network: Wi-Fi access
        </p>
        <p className="space-medium">
          The Canvas Dx App is compatible with the most recent and one previous version of iOS and Android operating systems. It was running on iOS 12 and 13, as well as Android 9 and 10 operating systems during clinical studies.
        </p>

        <h5>HCP Portal:</h5>
        <p className="space-medium">
          Computer Operating system: 	Mac (Catalina or Mojave) / Windows 10<br/>
          Browser version: Safari (12 or 13) / Chrome (84 or 85)
        </p>
        <p className="space-medium">
          The Canvas Dx HCP Portal supports the most recent and previous version of Chrome and Safari browsers running on the most recent and previous version of Mac and Windows operating systems. It was running on a Mac operating system (Catalina and Mojave) using Chrome (84 or 85) and Safari (12 or 13) browsers when validated. It has not been tested with other browsers such as Firefox or Microsoft Edge.
        </p>
        <p className="space-medium">
          Please be sure the mobile device, computer and browsers are running an Operating System (OS) version matching those above. If not, please take time to update to the most recent versions.
        </p>
      </div>

      <div>
        <h2 className="space-small">Cybersecurity</h2>
        <p className="space-medium">
          The measures below are recommended to manage cybersecurity-related risks when using the Device:
        </p>
        <p className="space-medium">
          It is recommended that you set a password on your computer. Do not share your password with others.
        </p>
        <p className="space-medium">
          The HCP Portal is password protected to reduce the risk of unauthorized access. Use a unique passcode that is at least 8 characters long. For security reasons, please do not share your password with anyone.
        </p>
        <p className="space-medium">
          Please log out of the HCP portal anytime you need to step away from your computer. The HCP Portal will log you out automatically after more than 30 minutes of inactivity.
        </p>
        <p className="space-medium">
          Avoid using untrusted Wi-Fi networks.
        </p>
        <p className="space-medium">
          The Canvas Dx HCP Portal supports the most recent and previous version of Chrome and Safari browsers running on the most recent and previous version of Mac and Windows operating systems. It is recommended that you keep your computer and internet browsers updated to the most recent versions.
        </p>
        <p className="space-medium">
          It is also highly recommended that you install security software to protect against malware and viruses.
        </p>
      </div>

      <div id="ug-1">
        <h2 className="space-small">Getting Started</h2>
        <p className="space-medium">
          You will receive an email which will contain your password to access the Portal. Do not share this password with others. This password will have 2 factor authentication and strong password requirements.
        </p>

        <h2 className="space-small">Instructions for 2 factor authentication</h2>
        <h3 className="space-small">Open Cognoa HCP Portal</h3>
        <ol>
          <li>Using a browser, navigate to the Cognoa HCP Portal (<a href="https://app.cognoa.com/hcp">https://app.cognoa.com/hcp</a>) and login using the username and password that was provided to you via secure email.</li>
          <li>Upon initial login, you will see a screen that asks you to set up two-factor authentication. You will need a smartphone device and an authenticator app. If you don’t already have one, we recommend using Google Authenticator.</li>
        </ol>
        <h3 className="space-small">Suggested Google Authenticator Steps</h3>
        <ol>
          <li>On your cell phone please navigate to the Apple or Google Store and download/install ‘Google Authenticator’</li>
          <li>Once installed, open the Google Authenticator app on your phone.</li>
          <li>Tap ‘Get Started’ or if you already use Google Authenticator click the + to add a new account.</li>
          <li>Follow the steps in the app to acquire the Time-based One-Time Password (TOTP).</li>
        </ol>
      </div>

      <div id="ug-2">
        <h2 className="space-small">Log in to Your Account</h2>
        <p className="space-small">
          Access the HCP Portal through https://app.cognoa.com. Consider bookmarking this website for easy access. Be sure your computer and internet browsers are updated to the most recent versions.
        </p>
        <p className="space-small">
          Log in to the HCP Portal using your email and the password that were previously provided and select the “Log In” button (see Figure 1).
        </p>

        <div className="guide-img">
          <img src={images.sign_in} alt="HCP sign in page" className="hcp--wide" />
        </div>
        <p className="blue-text space-medium hcp--caption">Figure 1. HCP Portal Login Screen</p>

        <p className="space-medium">
          If you have forgotten your password, select the “Password Reset” link on the login screen, enter your email address and select the “Send Reset Password” link. There will be an email sent to your email address with instructions on how to reset your password.
        </p>
        <p className="space-large">
          Upon logging in, you will be taken to the user guide. You will have the option to bypass the user guide upon subsequent logins (see Figure 2).
        </p>

        <div className="guide-img">
          <img src={images.figure2} alt="Figure 2" className="hcp--wide" />
        </div>
        <p className="blue-text space-medium hcp--caption">Figure 2. Don't show again</p>
      </div>

      <div id="ug-3">
        <h2 className="space-small">Dashboard</h2>
        <p className="space-medium">
          The dashboard provides you with an overview of all the patients for whom the Canvas Dx has been prescribed in your practice (see Figure 3).
        </p>
        <div className="guide-img">
          <img src={images.nav} alt="HCP Dashboard Nav Bar image" className="hcp--wide" />
        </div>
        <p className="blue-text space-medium hcp--caption">Figure 3. Dashboard</p>
        <ol>
          <li>Dashboard - click here to return to the dashboard.</li>
          <li>User Guide- access user guide.</li>
          <li>Log out - click here to log out of the portal.</li>
          <li>Practice Name - customized to your practice.</li>
          <li>Search bar - search by Caregiver name, Patient name, Patient DOB, or Doctor Name.</li>
          <li>Show entries - adjust to show a different number of entries per screen.</li>
        </ol>
        <p className="space-medium">
          The dashboard contains the following summary and descriptive information for each patient (see Figure 4).
        </p>

        <div className="guide-img">
          <img src={images.datatable} alt="HCP Dashboard image" className="hcp--wide" />
        </div>
        <p className="blue-text space-medium hcp--caption">Figure 4. HCP Portal Dashboard Summary and Description</p>
        <ol className="space-large">
          <li>Patient Name & DOB - Patient first and last name along with their date of birth.</li>
          <li>Caregiver Name - First and last name of the patient’s caregiver.</li>
          <li>Next Step - Patient status within the Cognoa device.</li>
          <ol className="list-alpha">
            <li>Answer Questions: Link to HCP questionnaire.</li>
            <li>View Results: Link to Device result.</li>
            <li>Awaiting Caregiver Answers: Caregiver has not yet completed caregiver questionnaire.</li>
            <li>Awaiting Video: Videos of child still required.</li>
            <li>Analysis Pending: Videos of child have been uploaded.</li>
          </ol>
          <li>HCP Name - First and last name of prescriber.</li>
        </ol>
      </div>

      <div id="ug-4">
        <h2 className="space-small">Healthcare Professional (HCP) Questionnaire</h2>
        <p className="space-small">
          There are two versions of the HCP questionnaire based on the patient’s age and the portal will present the relevant questions based on the patient’s age.
        </p>
        <ul className="space-medium">
          <li>Patients 18 through 47 months - 13 questions</li>
          <li>Patients 48 through 72 months - 15 questions</li>
        </ul>
        <p className="space-small">
          The HCP should answer the questionnaire when the “Answer Questions” button is available for selection in the Dashboard. The HCP questions are grouped into four categories and pertain to the following:
        </p>
        <ul className="space-medium">
          <li>Development</li>
          <li>Language and communication</li>
          <li>Social</li>
          <li>Sensory, repetitive & stereotypical behaviors</li>
        </ul>
        <p className="space-medium">
          The answers to the HCP questionnaire are based on HCP interactions with the caregiver and the patient. Please base your responses to the HCP questionnaire on the typical behavior of the patient and not solely based on behavior observed when the patient is ill, hungry, tired, starting a new medication, or otherwise disrupted from their typical daily routines and activities.
        </p>
        <p className="space-small">
          Please ensure all questions are answered. Do not leave any questions blank.
        </p>
        <p>&nbsp;</p>
        <p>Each question includes the following:</p>
        <ul className="space-medium">
          <li><img src={images.info} alt="Learn More" className="info-icon"/>which provides additional information and/or context to assist in answering the question.</li>
          <li>An answer choice of “No opportunity to assess.”</li>
          <li>The ability to change your responses to any questions prior to submitting.</li>
        </ul>
        <p className="space-small">
          Once all questions have been answered, the “Submit” button will become active (see Figure 5). If responses are ready to be submitted, select “Yes”. If any changes have to be made to the responses, select “Cancel” and make the changes, as necessary, prior to submission of the questionnaire.
        </p>
        <p className="space-small">
          <b>Warning:</b> Responses cannot be changed after you submit.
        </p>
        <div className="guide-img space-large">
          <img src={images.submit} alt="Response Submit" className="hcp--wide" />
        </div>
        <p className="blue-text space-medium">Figure 5. Confirmation of Questionnaire Submission</p>
      </div>

      <div id="ug-5">
        <h2 className="space-small">Results</h2>
        <p className="space-medium">
          To view a patient's result, login to the portal and select the “View Results” button from the dashboard when the button becomes available.
        </p>
        <p className="space-medium">
          <em>Device Result</em><br/>
          After the (3) inputs are completed (see Table 1), the algorithm underlying the Device evaluates the data and determines whether there is sufficient information to provide a diagnostic output. If the algorithm cannot provide a determination, you will be informed that the device is not able to provide a result for ASD at that point in time (“No Result”).
        </p>
        <p className="space-medium">
          If there is sufficient information, the algorithm will provide one of two distinct diagnostic outputs: Positive ASD, Negative ASD.
        </p>
        <p className="space-medium">
          Each result is accompanied by the clinical performance testing data regarding the device (see Clinical Study Results).
        </p>
        <h3 className="space-small">Positive ASD</h3>
        <p className="space-large">
          A Positive ASD result indicates there is a high probability that the patient has autism. The Device result is provided as an aid in the diagnosis of ASD and should be used in conjunction with the clinical presentation of the patient to determine if the DSM-5 diagnostic criteria for ASD are met.
        </p>
        <p className="space-medium">
          Figure 6, below, provides a screenshot of how the portal displays a Positive ASD result.
        </p>

        <div className="guide-img space-large">
          <img src={images.result_high} alt="Positive ASD Result" className="hcp--wide hcp--shadow" />
        </div>
        <p className="blue-text space-medium hcp--caption">Figure 6. Positive ASD Result</p>

        <h2 className="space-small">Negative ASD</h2>
        <p className="space-large">
          A Negative ASD result indicates there is a high probability that the patient does NOT have autism and should be used in conjunction with the clinical presentation of the patient to determine that the DSM-5 diagnostic criteria for ASD are NOT met. A negative result does not necessarily mean that the patient will not develop ASD in the future and continued monitoring or evaluation for non-ASD conditions may be warranted.
        </p>
        <p className="space-medium">
          Figure 7, below, provides a screenshot of how the portal displays a Negative ASD result.
        </p>

        <div className="guide-img space-small">
          <img src={images.result_low} alt="Negative ASD Result" className="hcp--wide hcp--shadow" />
        </div>

        <p className="blue-text space-medium hcp--caption">Figure 7. Negative ASD Result</p>

        <h2 className="space-small">No Result</h2>
        <p className="space-medium">
          In some cases, the Device does not provide a result based on the information obtained (“No Result”). It does not mean that the patient does not have autism.
        </p>
        <p className="space-medium">
          If there is insufficient information to render a “Positive for ASD” or “Negative for ASD” result to help determine a diagnosis, the ASD Diagnosis Aid will report that no result can be generated. The patient may exhibit an insufficient number and/or severity of features to be confidently classified as being Positive for ASD, but at the same time, exhibits an insufficient number and/or severity of features to be confidently classified as being Negative for ASD. The HCP should make the best decision for the patient at his/her own discretion and should follow generally accepted guidelines such as those of the American Academy of Pediatrics: Identification, Evaluation, and Management of Children with Autism Spectrum Disorder (January 2020). <b>Specifically, standardized testing is recommended when the device does not render a determinate outcome such as Positive or Negative for ASD, in order to drive clinical decision making.</b>
        </p>
        <p className="space-medium">
          Figure 8, below, provides a screenshot of how the portal displays a No Result.
        </p>
        <div className="guide-img space-small">
          <img src={images.result_no} alt="No Result" className="hcp--wide hcp--shadow" />
        </div>
        <p className="blue-text space-medium hcp--caption">Figure 8. No Result</p>

        <p className="space-small">
          For all patients, the HCP will have access to patient supporting information after all three inputs (per Table 1) have been completed and the Device has provided an output (per Table 2). Once all inputs have been provided, select the respective tab to view the supporting information.
        </p>
        <p className="space-large">
          <em><u>Caregiver Input</u></em><br/>
          By selecting the “Caregiver Input” tab, the HCP will be able to view the responses to the Caregiver questionnaire as well as the patient videos.
        </p>
        <p className="space-medium">
          Patient videos may be viewed through the portal if the system (e.g., web browser, IT infrastructure) allows the video playback. There is also the option to download the videos and use another player or system (See Figure 9).
        </p>

        <div className="guide-img space-small">
          <img alt="Caregiver Responses" src={images.caregiver_input} className="hcp--wide" />
        </div>
        <p className="blue-text space-medium hcp--caption">Figure 9. Example of caregiver responses</p>

        <p className="space-medium">
          <em><u>HCP Answers</u></em><br/>
          By selecting the “HCP Answers” tab, the HCP will be able to view the answers to the HCP questionnaire (See Figure 10).
        </p>
        <div className="guide-img space-small">
          <img alt="HCP Answers" src={images.answer_clinician} className="hcp--wide" />
        </div>
        <p className="space-medium hcp--caption blue-text">Figure 10. Example of HCP answers</p>
      </div>

      <div id="ug-6" >
        <h2 className="space-medium">Troubleshooting and Support</h2>
        <p className="space-medium">
          If you have any technical difficulties or questions regarding Device usage, refer to the troubleshooting information below (See Table 5) or contact Cognoa Support.
        </p>
        <p className="space-medium">
          Email: <a href="mailto:support@cognoa.com">support@cognoa.com</a>
          <br/>
          Phone: 1-866-4COGNOA
        </p>
        <table className="user-guide-table space-small">
          <thead>
            <tr>
              <th>Cognoa Device Output</th>
              <th>Output Meaning</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Unable to login or log out</td>
              <td>Try refreshing your browser and logging in and logging out again. If the issue remains, contact <a href="mailto:support@cognoa.com" className="contact-link wcag-inline-anchor">Cognoa Support</a> for further assistance.</td>
            </tr>
            <tr>
              <td>Forgot password or need to reset password</td>
              <td>

                <p className="space-small">
                  If you have forgotten your password, select the “Password Reset” link on the login screen, enter your email address and select the “Send Reset Password” link. An email will be sent to your email address with instructions on how to reset your password.
                </p>
                <p className="space-small">
                  If the issue remains, contact <a href="mailto:support@cognoa.com" className="contact-link wcag-inline-anchor">Cognoa Support</a> for further assistance.
                </p>
              </td>
            </tr>
            <tr>
              <td>HCP Portal is not working</td>
              <td>

                <p className="space-small">
                  Be sure your computer and internet browsers are updated to the latest versions.
                </p>
                <p className="space-small">
                  If the issue remains, contact <a href="mailto:support@cognoa.com" className="contact-link wcag-inline-anchor">Cognoa Support</a> for further assistance.
                </p>
              </td>
            </tr>
            <tr>
              <td>Don’t understand inclusion and exclusion criteria</td>
              <td>Please see the “Warnings and Precautions” and “Indications for Use” sections. If further information is required, contact <a href="mailto:support@cognoa.com" className="contact-link wcag-inline-anchor">Cognoa Support</a> for further assistance.</td>
            </tr>
            <tr>
              <td>Need clarification on HCP questionnaire content</td>
              <td>Contact <a href="mailto:support@cognoa.com" className="contact-link wcag-inline-anchor">Cognoa Support</a> for further assistance.</td>
            </tr>
            <tr>
              <td>Don’t understand the device result</td>
              <td>Contact <a href="mailto:support@cognoa.com" className="contact-link wcag-inline-anchor">Cognoa Support</a> for further assistance.</td>
            </tr>
            <tr>
              <td>Unable to view/access the device result and/or supporting information (e.g. technical failure)</td>
              <td>Contact <a href="mailto:support@cognoa.com" className="contact-link wcag-inline-anchor">Cognoa Support</a> for further assistance.</td>
            </tr>
            <tr>
              <td>Need a printed version of the User Guide</td>
              <td>Contact <a href="mailto:support@cognoa.com" className="contact-link wcag-inline-anchor">Cognoa Support</a> for further assistance.</td>
            </tr>
          </tbody>
        </table>
        <p className="space-medium blue-text">Table 5. Troubleshooting</p>
      </div>

      <div>
        <h2 className="space-medium">Mandatory Reporting of Child Abuse and Neglect</h2>
        <p>
          Per the Federal Child Abuse Prevention and Treatment Act (CAPTA), all States require healthcare professionals to report known or suspected instances of child abuse and neglect.
          If you suspect or know that a child is being abused or neglected, call or text 1.800.4.A CHILD (1.800.422.4453) or consult https://www.childwelfare.gov/ and contact your local child protective services office or law enforcement agency.
        </p>
      </div>

      <div id="ug-7">
        <h2 className="space-medium">Name and Place of Business of Manufacturer</h2>
        <ul className="no-dots hcp--no-buffer">
          <li>Cognoa, Inc.</li>
          <li>2185 Park Blvd</li>
          <li>Palo Alto, CA 94306</li><br/>
          <li>Website: <a href="https://www.cognoa.com" className="wcag-inline-anchor">{'https://www.cognoa.com'}</a></li>
          <li>Email: <a href="mailto:support@cognoa.com" className="contact-link wcag-inline-anchor">support@cognoa.com</a></li>
          <li>Phone: 1-866-4COGNOA</li>
        </ul>
        <p id="device-label">
          LBL-001 - HCP Portal Instructions for Use, Rev. K
          <br/>
          (2021-12-16)
        </p>
        <br/>
      </div>
    </main>
  )
}

export default UserGuideContent
